import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import App from './App';

import Invitation from './Invitation'

function Main() {


  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/Invitation" element={<Invitation/>} />

      {/* Add more routes as needed */}
    </Routes>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default AppWrapper;